<script>
/* eslint-disable vue/no-v-html */
</script>
<template>
  <b-card>
    <ejs-toast
      id="toast_type"
      ref="toastRef"
      :position="{ X: 'Right' }"
    />
    <div class="col-lg-12 control-section mt-2 p-0">
      <div class="content-wrapper">
        <!-- <div>
          <ejs-dropdownlist
            id="dropdownlist"
            ref="dropdown"
            :data-source="ddData"
            width="220px"
            :fields="fields"
            :change="valueChange"
            :value="ddValue"
          />
          <span
            id="msg"
            ref="msgelement"
            v-html="loadTime"
          />
          <br>
        </div> -->
        <ejs-grid
          id="overviewgrid"
          ref="overviewgrid"
          :data-source="listini"
          :selection-settings="selectionSettings"
          :edit-settings="editSettings"
          :filter-settings="filterOptions"
          :show-column-chooser="true"
          :allow-selection="true"
          :allow-sorting="true"
          :allow-filtering="true"
          :allow-resizing="true"
          :enable-virtualization="false"
          :allow-row-drag-and-drop="false"
          height="600"
          :pdf-header-query-cell-info="pdfHeaderQueryCellInfo"
          :allow-pdf-export="true"
          :allow-excel-export="true"
          row-height="38"
          :enable-hover="false"
          :enable-header-focus="true"
          :load="load"
          :toolbar="toolbar"
          :toolbar-click="toolbarClick"
          :action-begin="actionTableBegin"
          :action-complete="actionTableComplete"
          :allow-grouping="false"
          :allow-paging="true"
          :page-settings="pageSettings"
          :context-menu-items="contextMenuItems"
          :context-menu-click="contextMenuClick"
          :data-bound="dataBound"
          :command-click="commandColumm"
        >
          <e-columns>
            <e-column
              type="checkbox"
              :allow-filtering="false"
              :allow-sorting="false"
              width="60"
            />
            <e-column
              field="id"
              :visible="false"
              header-text="ID"
              :is-primary-key="true"
              width="130"
            />
            <e-column
              field="nome"
              header-text="Nome"
              width="200"
              :filter="filter"
              clip-mode="EllipsisWithTooltip"
            />
            <e-column
              field="predefinito"
              header-text="Predefinito"
              width="170"
              clip-mode="EllipsisWithTooltip"
              :template="templateListinoPredefinitoColonnaTabella"
            />
            <e-column
              field="abilitato"
              header-text="Abilitato"
              width="170"
              clip-mode="EllipsisWithTooltip"
              :template="templateListinoAbilitatoColonnaTabella"
            />
            <e-column
              field="formula"
              header-text="Formula"
              width="230"
              :filter="filter"
            />
            <e-column
              field="Azioni"
              :lock-column="true"
              header-text="Azioni"
              width="240"
              :template="cTemplate"
              :allow-editing="false"
              :commands="commands"
              :allow-sorting="false"
              :allow-filtering="false"
            />
          </e-columns>
        </ejs-grid>
      </div>
    </div>
    <ListinoEventHandler
      ref="listino-modal-event-handler"
      @update-listino="updateListinoToGrid"
    />
  </b-card>
</template>

<script>
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-str */
/* eslint-disable vue/no-side-effects-in-computed-properties */

import Vue from 'vue'
import {
  GridPlugin, Sort, Filter, Selection, PdfExport, ExcelExport, Toolbar, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll,
} from '@syncfusion/ej2-vue-grids'
// import { DropDownListPlugin } from '@syncfusion/ej2-vue-dropdowns'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import Ripple from 'vue-ripple-directive'
import ListinoEventHandler from '@core/components/catalog-listino/ListinoEventHandler.vue'
import { BCard, BButton, VBToggle, BInputGroup, BFormGroup, BFormInput, BAvatar, } from 'bootstrap-vue'
import { italian } from '@/@core/grid-translation/it-IT'
import { L10n, setCulture } from '@syncfusion/ej2-base'
import flatPickr from 'vue-flatpickr-component'
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import { Italian } from 'flatpickr/dist/l10n/it'

setCulture('it-IT')
L10n.load(italian)
Vue.use(GridPlugin)
//Vue.use(DropDownListPlugin)
Vue.use(ToastPlugin)

export default Vue.extend({
  components: {
    BCard,
    BButton,
    ListinoEventHandler,
    BInputGroup,
    BFormGroup,
    flatPickr,
    BFormInput,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  data: () => ({      defaultDate: '2021-02-20',
      listini: [],
      configFlatPicker: {
        wrap: false, // set wrap to true only when using 'input-group'
        altFormat: 'Y-m-d',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian, // locale for this instance only
      },
          cTemplate: function () {
          return { template : Vue.component('columnTemplate',{
             template: `<div class="image">
                    <img :src="image" :alt="altImage"/>
                </div>`,
                data: function() {
                    return {
                        data: {}
                    }
                },
                computed: {
                    image: function() {
                        return './' + this.data.EmployeeID + '.png'
                    },
                    altImage: function() {
                        return this.data.EmployeeID
                    }
                }
          })
          }
          },
    warehouse: {},
    commands: [
        { type: 'Elimina', id: 'Delete', buttonOption: { iconCss: 'e-icons e-delete', cssClass: 'e-flat' } },
        { type: 'Save', buttonOption: { iconCss: 'e-icons e-update', cssClass: 'e-flat' } },
        { type: 'Cancel', buttonOption: { iconCss: 'e-icons e-cancel-icon', cssClass: 'e-flat' } },
        { type: 'Modifica Listino', id: 'editFormula', target: '.e-column', buttonOption: { iconCss: 'e-icons e-edit', cssClass: 'e-flat' } }],
    contextMenuItems: ['AutoFit', 'AutoFitAll', 'SortAscending', 'SortDescending', 'Add',
      'Copy', 'Save', 'Cancel',
      'PdfExport', 'ExcelExport', 'CsvExport', 'FirstPage', 'PrevPage',
      'LastPage', 'NextPage',  'Group', 'Ungroup',
        {
          text: 'Elimina', tooltipText: 'Delete', iconCss: 'e-icons e-delete', id: 'DeleteCustom',
        },
      {text: 'Modifica Listino', target: '.e-content', id: 'editFormula', iconCss: 'e-icons e-edit' }],
    pageSettings: { pageSize: 25, pageSizes: [25,50,100,200], pageCount: 5 },
    showModalSettingPdfExport: false,
    editSettings: { allowFiltering: true, allowEditing: false, allowAdding: true, allowDeleting: true, mode: 'Normal' },
    toolbar: [
      {
        text: 'Aggiungi', tooltipText: 'Aggiungi', prefixIcon: 'e-plus', id: 'Aggiungi',
      },
      {
        text: 'Elimina', tooltipText: 'Elimina', prefixIcon: 'e-delete', id: 'Elimina',
      },
      'ExcelExport', 'PdfExport', 'CsvExport', 'ColumnChooser', 'Print'],
    toolbarOptions: ['ExcelExport'],
    ddData: [{ value: 1000, text: '1,000 Rows and 11 Columns' }, { value: 10000, text: '10,000 Rows and 11 Columns' }],
    ddValue: 10000,
    stTime: null,
    loadTime: null,
    dReady: false,
    isDataChanged: true,
    fields: { text: 'text', value: 'value' },
    empTemplate() {
      return {
        template: Vue.component('empTemplate', {
          data() { return { data: {}, usermale: 'usermale', userfemale: 'userfemale' } },
          template: `<div>
                            <div class="e-grid-empimg">
                                <span :class="{'e-userimg': true, 'sf-grid-icon-Male': data.EmployeeImg == usermale, 'sf-grid-icon-FeMale': data.EmployeeImg == userfemale}"></span>
                                </span>
                            </div>
                            <span id="Emptext">{{data.Employees}}</span>
                        </div>`,
        }),
      }
    },
    templateListinoAbilitatoColonnaTabella() {
      return {
        template: Vue.component('templateListinoAbilitatoColonnaTabella', {
          components: {
            BAvatar,
          },
          data() { return { data: {} } },
          template: `
          <div id="producter" class="">
            <b-avatar :variant="data.abilitato == 1 ? 'success' : 'danger'">
            <feather-icon
              :icon="data.abilitato == 1 ? 'CheckIcon' : 'XIcon'"
              size="14"
            />
          </b-avatar>
          </div>`,
        }),
      }
    },
    templateListinoPredefinitoColonnaTabella() {
      return {
        template: Vue.component('templateListinoPredefinitoColonnaTabella', {
          components: {
            BAvatar,
          },
          data() { return { data: {} } },
          template: `
          <div id="producter" class="">
            <b-avatar
              v-if="data.predefinito === 1"
              :variant="data.predefinito == 1 ? 'success' : null"
            >
              <feather-icon
                :icon="'CheckIcon'"
                size="14"
              />
            </b-avatar>
          </div>`,
        }),
      }
    },
    filterOptions: {
      type: 'Menu',
    },
    filter: {
      type: 'CheckBox',
    },
    selectionSettings: { persistSelection: false, type: 'Multiple', checkboxOnly: true },
  }),
  computed: {
    getTradeData() {
      let dataCount = this.ddValue
      const employees = [
        'Michael', 'Kathryn', 'Tamer', 'Martin', 'Davolio', 'Nancy', 'Fuller', 'Leverling', 'Peacock',
        'Margaret', 'Buchanan', 'Janet', 'Andrew', 'Callahan', 'Laura', 'Dodsworth', 'Anne',
        'Bergs', 'Vinet', 'Anton', 'Fleet', 'Zachery', 'Van', 'King', 'Jack', 'Rose']
      const designation = ['Manager', 'CFO', 'Designer', 'Developer', 'Program Directory', 'System Analyst', 'Project Lead']
      const mail = ['sample.com', 'arpy.com', 'rpy.com', 'mail.com', 'jourrapide.com']
      const location = ['UK', 'USA', 'Sweden', 'France', 'Canada', 'Argentina', 'Austria', 'Germany', 'Mexico']
      const status = ['Active', 'Inactive']
      const trustworthiness = ['Perfect', 'Sufficient', 'Insufficient']
      const tradeData = []
      const address = ['59 rue de lAbbaye', 'Luisenstr. 48', 'Rua do Paço, 67', '2, rue du Commerce', 'Boulevard Tirou, 255',
        'Rua do mailPaço, 67', 'Hauptstr. 31', 'Starenweg 5', 'Rua do Mercado, 12',
        'Carrera 22 con Ave. Carlos Soublette #8-35', 'Kirchgasse 6',
        'Sierras de Granada 9993', 'Mehrheimerstr. 369', 'Rua da Panificadora, 12', '2817 Milton Dr.', 'Kirchgasse 6',
        'Åkergatan 24', '24, place Kléber', 'Torikatu 38', 'Berliner Platz 43', '5ª Ave. Los Palos Grandes', '1029 - 12th Ave. S.',
        'Torikatu 38', 'P.O. Box 555', '2817 Milton Dr.', 'Taucherstraße 10', '59 rue de lAbbaye', 'Via Ludovico il Moro 22',
        'Avda. Azteca 123', 'Heerstr. 22', 'Berguvsvägen  8', 'Magazinweg 7', 'Berguvsvägen  8', 'Gran Vía, 1', 'Gran Vía, 1',
        'Carrera 52 con Ave. Bolívar #65-98 Llano Largo', 'Magazinweg 7', 'Taucherstraße 10', 'Taucherstraße 10',
        'Av. Copacabana, 267', 'Strada Provinciale 124', 'Fauntleroy Circus', 'Av. dos Lusíadas, 23',
        'Rua da Panificadora, 12', 'Av. Inês de Castro, 414', 'Avda. Azteca 123', '2817 Milton Dr.']
      const employeeimg = ['usermale', 'userfemale']
      if (typeof dataCount === 'string') {
        dataCount = parseInt(dataCount, 10)
      }
      for (let i = 1; i <= dataCount; i++) {
        const code = 10000
        tradeData.push({
          id: code + i,
          nome:
                        `${employees[Math.floor(Math.random() * employees.length)]} ${employees[Math.floor(Math.random() * employees.length)]}`,
          predefinito: status[Math.floor(Math.random() * status.length)],
          abilitato: location[Math.floor(Math.random() * location.length)],
          formula: Math.floor(Math.random() * employees.length),
        })
      }
      this.stTime = performance.now()
      return tradeData
    },
  },
  mounted() {
    this.getListini()
  },
  methods: {
    dataBound: function() {
      // console.log(this.$refs.overviewgrid)
      this.$refs.overviewgrid.autoFitColumns(['Azioni' ]);
    },
    commandColumm(args) {
      if(args.commandColumn.type === 'Modifica Listino') {
        // console.log('argsss', args.rowData)
        this.$refs['listino-modal-event-handler'].setTitle('Modifica Listino')
        this.$refs['listino-modal-event-handler'].setListino(args.rowData)
        this.$refs['listino-modal-event-handler'].setNamesOfListiniUnique(this.listini.map(listino => listino.nome))
        this.$refs['listino-modal-event-handler'].openModal()

        }
      if (args.commandColumn.type === 'Elimina') {
        // console.log([{ id: args.rowData.id }])
        this.alertAndConfirm([{ id: args.rowData.id }])
      }
    },
    contextMenuClick(args) {
        // console.log('contextMenuClick', args)
        if(args.item.id === 'editFormula') {
          this.$refs['listino-modal-event-handler'].setTitle('Modifica Listino')
          this.$refs['listino-modal-event-handler'].setListino(args.rowInfo.rowData)
          this.$refs['listino-modal-event-handler'].setNamesOfListiniUnique(this.listini.map(listino => listino.nome))
          this.$refs['listino-modal-event-handler'].openModal()
        }
        else if (args.item.id === 'Dettagli' && 'rowData' in args.rowInfo) {
          this.showDetails(args.rowInfo.rowData.id)
        } else if (args.item.id === 'DeleteCustom') {
          this.alertAndConfirm([{ id: args.rowInfo.rowData.id }])
        }
    },
    alertAndConfirm(id) {
      // console.log('sono su alert confirm id', id)
      this.$swal({
        title: 'Sei Sicuro?',
        text: 'Verranno rimossi permanentemente',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        cancelButtonText: 'Annulla',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(res => {
        if (res.value) {
          this.deleteListino(id)
        }
      })
    },
    deleteListino(id) {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      // console.log(recordToDelete, 'wow')
      axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify(recordToDelete),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Listino',
            metodo: 'deleteListini',
            ACL: '',
          },
        }).then(res => {
        console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('res', res)
          recordToDelete.forEach(record => this.$refs.overviewgrid.deleteRecord('id', record))
          // mixins
          // this.productDeletedCorrectlyAlert()
          this.$refs.toastRef.show({
            title: 'Operazione Completata!', content: 'Listino/i Eliminato/i Correttamente', cssClass: 'e-toast-success', icon: 'e-success toast-icons',
          })
        } else if (res.data.esito === 'KO') {
          this.$refs.toastRef.show({
            title: 'Errore Cancellazione', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
          })
        }
      }).catch(e => e)
    },
    closeModal() {
      this.showModalSettingPdfExport = false
    },
    actionTableBegin() {
      // console.log(action)
    },
    actionTableComplete() {
      // console.log(row)
    },
    pdfHeaderQueryCellInfo() {
      // args.cell.row.pdfGrid.repeatHeader = true;
    },
     toolbarClick(args) {
        // console.log(args)
        if (args.item.id === 'Aggiungi') {
          this.$refs['listino-modal-event-handler'].setTitle('Aggiungi Listino')
          this.$refs['listino-modal-event-handler'].setNamesOfListiniUnique(this.listini.map(listino => listino.nome))
          this.$refs['listino-modal-event-handler'].resetListino()
          this.$refs['listino-modal-event-handler'].openModal()
        }
        if (args.item.id === 'editFormula') {
          this.$refs['listino-modal-event-handler'].setTitle('Modifica Listino')
          this.$refs['listino-modal-event-handler'].setListino(args.rowInfo.rowData)
          this.$refs['listino-modal-event-handler'].setNamesOfListiniUnique(this.listini.map(listino => listino.nome))
          this.$refs['listino-modal-event-handler'].openModal()
        }
        if (args.item.id === 'Elimina' && this.$refs.overviewgrid.getSelectedRecords().length > 0) {
          // console.log('delete')
          this.alertAndConfirm()
        }
        if (args.item.id === 'overviewgrid_pdfexport') { // 'Grid_pdfexport' -> Grid component id + _ + toolbar item name
        const exportProperties = {
          // you can customize the name as per your requirement
          fileName: `Listini$_{moment().format('DD-MM-YYYY')}.pdf`,
        }
            // this.$bvModal.show('setting-pdf-export-modal')
            this.$refs.overviewgrid.pdfExport(exportProperties)
            // this.$refs.overviewgrid.pdfExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const exportProperties = {
            // you can customize the name as per your requirement
            fileName: `Listini_${moment().format('DD-MM-YYYY')}.xslx`,
          }
          this.$refs.overviewgrid.excelExport(exportProperties)
        }
        if (args.item.id === 'overviewgrid_csvexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
            const exportProperties = {
              // you can customize the name as per your requirement
              fileName: `Listini_${moment().format('DD-MM-YYYY')}.csv`,
            }
            this.$refs.overviewgrid.csvExport(exportProperties)
        }
     },
    valueChange(args) {
      this.$refs.dropdown.$el.ej2_instances[0].closePopup()
      this.$refs.overviewgrid.showSpinner()
      this.isDataChanged = true
      this.stTime = null
      const contentElement = this.$refs.overviewgrid.ej2Instances.contentModule.content
      this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage = 1
      contentElement.scrollLeft = 0
      contentElement.scrollTop = 0
      // console.log(this.$refs.overviewgrid.ej2Instances.pageSettings.currentPage)
      this.stTime = performance.now()
      this.ddValue = args.value
      this.$refs.overviewgrid.hideSpinner()
    },
    load() {
      const proxy = this
      this.$refs.overviewgrid.$el.ej2_instances[0].on('data-ready', () => {
        proxy.dReady = true
      })
      this.$refs.overviewgrid.$el.addEventListener('DOMSubtreeModified', () => {
        if (proxy.dReady && proxy.stTime && proxy.isDataChanged) {
          const e = performance.now() - proxy.stTime
          proxy.loadTime = `Load Time: <b>${e.toFixed(0)}</b><b>ms</b>`
          proxy.stTime = null
          proxy.dReady = false
          proxy.isDataChanged = false
          // proxy.$refs.msgelement.classList.remove('e-hide')
        }
      })
    },
    async getListini() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, {},
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Listino',
            metodo: 'getListini',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok get listini')
          this.listini = [...res.data.listini]
          // console.log(this.listini)
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    async deleteListini() {
      let recordToDelete = []
      if (id === undefined) recordToDelete = this.$refs.overviewgrid.getSelectedRecords().map(el => ({ id: el.id }))
      else recordToDelete = id
      await axios.post(process.env.VUE_APP_URL_API_SERVER, recordToDelete,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Listino',
            metodo: 'deleteListini',
            ACL: '',
          },
        }).then(res => {
        console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok delete listini')
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    updateListinoToGrid(listini) {
      console.log('emitter', listini)
      let typeOperation = ''
      listini.forEach(element => {
        const index = this.$refs.overviewgrid.getRowIndexByPrimaryKey({ id: element.id})
        if(index >= 0) {
          this.$refs.overviewgrid.updateRow(index, element)
          typeOperation = 'Aggiornato/i'
        } else {
          typeOperation = 'Aggiunto/i'
          this.$refs.overviewgrid.addRecord(element, 0)
        }
      })
      // ad ogni update, controllo se il listino è predefinito, in caso mappo gli altri false.
      if(listini[0].predefinito === 1 ) {
        this.listini = this.listini.map(el => ({ ...el, predefinito: Number(el.id) === Number(listini[0].id) ? 1 : 0 }))
      }
      // console.log(this.listini, 'dopo mapping')
      this.$refs.toastRef.show({
        title: 'Operazione Completata!', content: `'Listino/i ${typeOperation} Correttamente'`, cssClass: 'e-toast-success', icon: 'e-success toast-icons',
      })
    },
  },
  provide: {
    grid: [Toolbar, PdfExport, ExcelExport, Filter, Selection, Sort, Edit, Page, ColumnChooser, ContextMenu, CommandColumn, Resize, Scroll, Filter],
  },

})

</script>
<style scoped>
/* @import "../../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/bootstrap.css"; */
</style>
