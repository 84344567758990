<template>
  <b-modal
    id="modal-list-prices-forum"
    ref="modal-list-prices-forum"
    v-model="show"
    ok-only
    ok-title="Crea"
    centered
    size="md"
    no-close-on-backdrop
    :title="titleEventHandler"
    @ok.prevent="handleOk"
  >
    <!-- {{ listiniUnici }} -->
    <validation-observer ref="listino-form-observer">
      <div>
        <b-alert
          variant="danger"
          :show="!nomeListinoIsUnique"
        >
          <h4 class="alert-heading">
            Puoi Inserire solo Listini con nomi diversi
          </h4>
        </b-alert>
        <b-row no-gutters>
          <b-col>
            <validation-provider
              #default="{ errors }"
              name="Nome"
              :rules="{ required: true, regex: /^\S*$/ }"
            >
              <b-form-group label="Nome Listino">
                <b-input-group>
                  <b-form-input
                    v-model="listino.nome"
                    placeholder="listino vivo"
                  />
                </b-input-group>
                <small
                  v-if="errors[0]"
                  class="text-danger mt-25"
                >Campo richiesto e senza spazi</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-end">
            <div>
              <b-card-text class="mb-0">
                Abilitato
              </b-card-text>
              <b-form-checkbox
                v-model="listino.abilitato"
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                value="1"
                unchecked-value="0"
              />
            </div>
            <div>
              <b-card-text class="mb-0">
                Predefinito
              </b-card-text>
              <b-form-checkbox
                v-model="listino.predefinito"
                checked="false"
                class="custom-control-primary"
                name="check-button"
                value="1"
                unchecked-value="0"
                switch
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <div>
        <b-form-group label="Inserisci il listino base da cui vuoi partire">
          <v-select
            v-model="listino.valorePartenza"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="nome"
            :clearable="false"
            filterable
            :reduce="valore => valore.nome"
            :options="valorePartenzaOption"
          />
        </b-form-group>
        <div v-if="listino.valorePartenza !== 'MANUALE'">
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="listino.movimentazionePercentuale"
              name="some-radios"
              value="+"
            >
              Aumenta
            </b-form-radio>
            <b-form-radio
              v-model="listino.movimentazionePercentuale"
              name="some-radios"
              value="-"
            >
              Diminuisci
            </b-form-radio>
          </div>
          <b-form-group class="mt-1">
            <b-input-group append="%">
              <b-form-input
                v-model="listino.valorePercentuale"
                placeholder="3%"
              />
            </b-input-group>
          </b-form-group>
          <div class="demo-inline-spacing">
            <b-form-radio
              v-model="listino.movimentazioneEuro"
              name="some-radios1"
              value="+"
            >
              Aumenta
            </b-form-radio>
            <b-form-radio
              v-model="listino.movimentazioneEuro"
              name="some-radios1"
              value="-"
            >
              Diminuisci
            </b-form-radio>
          </div>
          <b-form-group class="mt-1">
            <b-input-group append="€">
              <b-form-input
                v-model="listino.valoreEuro"
                placeholder="3€"
              />
            </b-input-group>
          </b-form-group>
        </div>
        <!-- <b-card-text>{{ listino }}</b-card-text> -->
        <b-card-text>{{ listinoCostructor }}</b-card-text>
      </div>
      <ejs-toast
        id="toast_type"
        ref="toastRef"
        :position="{ X: 'Right' }"
      />
    </validation-observer>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import {
  BCardText, BFormGroup, BInputGroup, BFormInput, BFormRadio, BFormCheckbox, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import { ToastPlugin } from '@syncfusion/ej2-vue-notifications'
import vSelect from 'vue-select'
import axios from 'axios'
import { setListini } from '@/@core/api-service/catalog/priceList'

Vue.use(ToastPlugin)

export default {
  components: {
    BRow, BCol, BCardText, BFormGroup, BInputGroup, BFormInput, vSelect, BFormRadio, BFormCheckbox, ValidationProvider, ValidationObserver, BAlert,
  },
  data() {
    return {
      listiniUnici: [],
      nomeListinoIsUnique: true,
      locale: 'it',
      required,
      show: false,
      titleEventHandler: '',
      newlistino: 0,
      listino: {
        id: 0,
        nome: '0',
        valorePartenza: 'COSTO',
        movimentazionePercentuale: '+',
        valorePercentuale: '0.00',
        movimentazioneEuro: '+',
        valoreEuro: '0.00',
        abilitato: '1',
        predefinito: '0',
      },
      valorePartenzaOption: [
        {
          id: 1,
          nome: 'COSTO',
        },
        {
          id: 2,
          nome: 'PREZZO',
        },
        {
          id: 3,
          nome: 'MANUALE',
        },
      ],
    }
  },
  computed: {
    listinoCostructor() {
      return '['
        .concat(this.listino.valorePartenza)
        .concat(']')
        .concat(this.listino.movimentazionePercentuale)
        .concat(this.listino.valorePercentuale)
        .concat('%')
        .concat(this.listino.movimentazioneEuro)
        .concat(this.listino.valoreEuro)
        .concat('€')
    },
  },
  mounted() {
    localize(this.locale)
  },
  methods: {
    handleOk() {
      // console.log(!this.listiniUnici.some(el => el === String(this.listino.nome)))
      if (this.listiniUnici.some(el => el.toUpperCase() === String(this.listino.nome.toUpperCase())) && this.listino.id === 0) {
        this.nomeListinoIsUnique = false
      } else {
        this.$refs['listino-form-observer'].validate().then(success => {
          if (success) {
            setListini(JSON.stringify([this.listino]))
              .then(res => {
                // console.log(res)
                if (res.data.esito === 'OK') {
                  // console.log('ok')
                  const listini = [...res.data.listini.map(el => ({
                    id: el.id,
                    abilitato: el.abilitato,
                    nome: el.nome,
                    predefinito: Number(el.predefinito),
                    formula: el.formula,
                    movimentazionePercentuale: el.movimentazione_percentuale,
                    movimentazioneEuro: el.movimentazione_euro,
                    valoreEuro: el.valore_euro,
                    valorePartenza: el.valore_partenza,
                    valorePercentuale: el.valore_percentuale,
                  }))]
                  // console.log('listini', listini)
                  this.$emit('update-listino', listini)
                  this.resetListino()
                  this.$nextTick(() => {
                    this.$refs['modal-list-prices-forum'].toggle('#toggle-btn')
                  })
                } else if (res.data.esito === 'KO') {
                  this.$refs.toastRef.show({
                    title: 'Errore Aggiunta', content: res.data.messaggio, cssClass: 'e-toast-danger', icon: 'e-error toast-icons',
                  })
                }
              })
              .catch(e => e)
          }
        })
      }
      // console.log('this.categories', this.categories)
    },
    setListino(listino) {
      this.listino = { ...listino }
    },
    openModal() {
      this.show = true
    },
    setNamesOfListiniUnique(listiniUnici) {
      // console.log('listiniunici', listiniUnici)
      this.listiniUnici = [...listiniUnici]
    },
    setTitle(title) {
      this.titleEventHandler = title
    },
    async setListini() {
      await axios.post(process.env.VUE_APP_URL_API_SERVER, JSON.stringify([this.listino]),
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('token')}`,
            organizzazione: JSON.parse(sessionStorage.getItem('activeAgency')).value,
            classe: 'Cat-Listino',
            metodo: 'setListino',
            ACL: '',
          },
        }).then(res => {
        // console.log(res)
        if (res.data.esito === 'OK') {
          // console.log('ok')
          const listini = [...res.data.listini.map(el => ({
            id: el.id,
            abilitato: el.abilitato,
            nome: el.nome,
            predefinito: el.predefinito,
            formula: el.formula,
            movimentazionePercentuale: el.movimentazione_percentuale,
            movimentazioneEuro: el.movimentazione_euro,
            valoreEuro: el.valore_euro,
            valorePartenza: el.valore_partenza,
            valorePercentuale: el.valore_percentuale,
          }))]
          // console.log('listini', listini)
          this.$emit('update-listino', listini)
          this.resetListino()
          this.$nextTick(() => {
            this.$refs['modal-list-prices-forum'].toggle('#toggle-btn')
          })
        }
      })
        .catch(e => e)
      // console.log('this.categories', this.categories)
    },
    resetListino() {
      this.listino = {
        id: 0,
        nome: '',
        valorePartenza: 'COSTO',
        movimentazionePercentuale: '+',
        valorePercentuale: '0.00',
        movimentazioneEuro: '+',
        valoreEuro: '0.00',
        abilitato: '1',
        predefinito: '0',
      }
      this.nomeListinoIsUnique = true
    },
  },
}
</script>

<style>

</style>
